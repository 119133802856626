.table-responsive {
  overflow: hidden;
}

.account-table {
  font-size: 15px;
  border: 1px solid #dee2e6;
}

.account-table th,
.account-table td {
  vertical-align: middle;
  text-align: center;
  border: 1px solid #dee2e6;
}

.account-table th {
  background-color: #f8f9fa;
  font-weight: bold;
}

.search-input::placeholder {
  font-size: 15px;
}

.account-table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-responsive .btn {
  padding: 0.25rem 0.5rem;
  font-size: 10px;
}

.iframe-container {
  padding-left: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  height: 900px;
}

