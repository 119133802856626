/**
 * Blog
 */

.tm-blog {

    &-topside {
        overflow: hidden;
        position: relative;
    }

    &-imageslider {
        &.slick-slider {
            .slick-arrow {
                height: 35px;
                width: 35px;
                font-size: 14px;
            }
        }
    }

    &-metahighlight {
        position: absolute;
        width: 100px;
        height: 70px;
        left: 0;
        top: 0;
        background: $color-black;
        color: #ffffff;
        text-align: center;
        padding-left: 30px;
        line-height: 70px;
        font-family: $ff-heading;
        font-weight: 600;
        font-size: 30px;
        overflow: hidden;

        span {
            position: absolute;
            left: 0;
            top: 50%;
            background: $color-theme;
            font-size: 16px;
            line-height: 30px;
            width: 100%;
            height: 30px;
            transform: translate(-35%, -50%) rotate(-90deg);
        }
    }

    &-title {
        font-weight: 700;
        font-size: 20px;
        font-family: $ff-body;

        a {
            color: $color-heading;

            &:hover {
                color: $color-theme;
            }
        }
    }

    &-content {
        margin-top: 25px;

        blockquote {
            margin-top: 25px;
            margin-bottom: 25px;
        }
    }

    &-meta {
        padding-left: 0;
        margin-bottom: 0;
        margin-bottom: 12px;

        li {
            list-style: none;
            display: inline-block;
            margin-bottom: 5px;

            &:not(:last-child) {
                margin-right: 15px;
            }

            i {
                color: $color-theme;
                margin-right: 5px;
            }

            a {
                color: $color-body;

                &:hover {
                    color: $color-theme;
                }
            }
        }
    }
}


/* Blog Details */
.blogitem {

    .tm-blog-title {
        font-size: 26px;
    }

    .tm-blog-image {
        img {
            transform: scale(1);
        }
    }

    &-tags,
    &-share {
        margin-top: 30px;
        margin-bottom: 30px;
        border-top: 1px solid #efefef;
        border-bottom: 1px solid #efefef;
        padding-top: 20px;
        padding-bottom: 20px;

        &-title {
            display: inline-block;
            margin-bottom: 0;
            margin-right: 15px;
            line-height: 40px;
        }

        ul {
            display: inline-block;
            padding-left: 0;
            margin-bottom: 0;
            margin-top: -10px;
            margin-left: -10px;
            font-size: 0;

            li {
                display: inline-block;
                margin-left: 10px;
                margin-top: 10px;
                font-size: 14px;

                a {
                    display: inline-block;
                    vertical-align: middle;
                    border: 1px solid $color-border;
                    padding: 6px 22px;
                    color: $color-body;

                    &:hover {
                        background: $color-theme;
                        border-color: $color-theme;
                        color: #ffffff;
                    }
                }
            }
        }
    }

    &-tags {
        margin-bottom: 0;
    }

    &-share {
        border-top: 0;
        margin-top: 0;

        ul {
            li {
                a {
                    i {
                        font-size: 18px;
                        margin-right: 5px;
                    }
                }

                &.facebook {
                    a {
                        color: $facebook;
                        border-color: $facebook;

                        &:hover {
                            background: $facebook;
                        }
                    }
                }

                &.twitter {
                    a {
                        color: $twitter;
                        border-color: $twitter;

                        &:hover {
                            background: $twitter;
                        }
                    }
                }

                &.linkedin {
                    a {
                        color: $linkedin;
                        border-color: $linkedin;

                        &:hover {
                            background: $linkedin;
                        }
                    }
                }

                &.pinterest {
                    a {
                        color: $pinterest;
                        border-color: $pinterest;

                        &:hover {
                            background: $pinterest;
                        }
                    }
                }

                a {
                    &:hover {
                        color: #ffffff;
                    }
                }
            }
        }
    }

    &-author {
        background: $color-grey;
        padding: 30px;
        padding-bottom: 15px;
        display: flex;

        &-image {
            flex: 0 0 100px;
            align-self: flex-start;
            max-width: 100px;
            border-radius: 1000px;
            overflow: hidden;
            margin-right: 30px;
        }
    }


    @media #{$layout-tablet} {
        .tm-blog-title {
            font-size: 22px;
        }
    }

    @media #{$layout-mobile-lg} {
        .tm-blog-title {
            font-size: 22px;
        }

        &-tags {
            ul {
                li {
                    a {
                        padding: 5px 15px;
                    }
                }
            }
        }
    }

    @media #{$layout-mobile-sm} {
        &-author {
            flex-wrap: wrap;

            &-content {
                margin-top: 20px;
            }
        }
    }
}