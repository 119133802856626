.table-responsive {
  overflow-x: auto;
}

.account-table {
  font-size: 13px; /* Điều chỉnh kích thước phông chữ */
  border: 1px solid #dee2e6;
}

.account-table th,
.account-table td {
  vertical-align: middle;
  text-align: center;
  border: 1px solid #dee2e6; /* Đảm bảo rằng các ô có đường viền */
}

.account-table th {
  background-color: #f8f9fa;
  font-weight: bold;
}

.account-table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.password-cell {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 50px;
}

.password-cell:hover {
  overflow: visible;
  white-space: normal;
  width: auto;
  position: relative;
}

.table-responsive .btn {
  padding: 2px 5px; /* Điều chỉnh kích thước nút */
  font-size: 16px;
}

/* Override margins for .MuiFormControlLabel-root when it's a descendant of .checkbox-align */
.checkbox-align {
  margin-left: 0px !important; /* Remove left margin */
  margin-right: 0px !important; /* Remove right margin */
}

/* Target the Material-UI Checkbox directly if needed. Material-UI uses the .MuiCheckbox-root class for its checkbox. */
.checkbox-align .MuiCheckbox-root {
  /* Adjustments specific to the checkbox */
  color: blue; /* Example: Change the default color (applies when not checked) */
}

/* For the checked state and color, it's controlled via the `color` prop in your JSX, which corresponds to theme colors. */

/* If you need to target the label of the FormControlLabel, you might need to use Material-UI's `classes` prop or styled components for more specific styling. */

/* Additional styling for hover effect on rows */
.table-responsive .account-table tr:hover {
  background-color: #ddd; /* Light grey background on hover */
}

/* Styling for active/inactive status */
.active-status {
  color: green;
}

.inactive-status {
  color: red;
}