/**
 * Team Member
 */

.tm-member {
    &-topside {
        position: relative;
        overflow: hidden;

        img{
            width: 100%;
        }

        ul {
            padding-left: 0;
            margin-bottom: 0;
            position: absolute;
            left: 0;
            top: auto;
            bottom: 0;
            width: 100%;
            background: linear-gradient(0, rgba(0,0,0,0.4) 0%, transparent) 50%;
            padding: 10px 20px;
            text-align: center;
            font-size: 0;
            transition: $transition;
            transform: translateY(100%);
            height: 40%;
            display: flex;
            align-items: flex-end;
            justify-content: center;

            li {
                list-style: none;
                display: inline-block;
                font-size: 18px;
                vertical-align: middle;
                margin: 0 10px;

                a,
                button {
                    display: inline-block;
                    vertical-align: middle;
                    padding: 2px 5px;
                    color: #ffffff;
                    outline: none;
                    border: none;
                    height: auto;

                    &:hover{
                        color: $color-theme;
                    }
                }
            }
        }
    }

    &-bottomside {
        padding-top: 25px;

        h6 {
            font-family: $ff-body;
            color: $color-heading;
            font-weight: 600;
            margin-bottom: 5px;
        }

        p {
            margin-bottom: 0;
        }
    }

    &:hover &-topside {
        ul {
            transform: translateY(0);
        }
    }
}