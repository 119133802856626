.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(167, 167, 166, 0.719);
    z-index: 1000;
  }
  
  .loading-image {
    width: 120px;
    height: 120px;
    border-radius: 20px;
  }
  