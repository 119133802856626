/**
 * Newsletter
 */

.tm-newsletter {
    &-title {
        margin-bottom: 0;
        font-weight: 700;
    }

    &-form {
        background: #ffffff;
        border: 1px solid $color-border;
        border-radius: 5px;
        padding: 3px;
        display: flex;

        input {
            background: #ffffff;
            border: 0;
            outline: none;
            flex-shrink: 100;
        }

        button,
        input[type="submit"] {
            flex-grow: 1;
        }
    }

    &-call {
        padding-left: 30px;
        border-left: 1px solid #c5c6c7;

        p {
            margin-bottom: 0;
            color: $color-secondary;
        }

        h3 {
            margin-bottom: 0;
            line-height: 1;

            a {
                color: $color-heading;
                font-weight: 700;

                &:hover {
                    color: $color-theme;
                }
            }
        }
    }

    @media #{$layout-notebook} {
        &-title {
            margin-bottom: 20px;
        }
    }

    @media #{$layout-tablet} {
        &-title {
            margin-bottom: 20px;
        }
    }

    @media #{$layout-mobile-lg} {
        &-title {
            margin-bottom: 20px;
        }

        &-call{
            margin-top: 20px;
        }
    }
}