.sidebar-admin img {
  width: 40%;
  height: 70%;
  margin-right: 5px;
}

.sidebar-admin {
  size: 50px;
}

.sidebar-full-height {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  overflow: hidden;
  font-size: 15px;
}

.sidebar-content {
  padding: 5px;
  overflow-x: auto;
  max-height: 100vh;
  
}

.sidebar-nav-item {
  text-decoration: none;
  align-items: center;
  padding: 12px 16px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
}

.sidebar-nav-item:hover {
  border-radius: 0.375rem;
  background-color: #3e4659;
  filter: brightness(150%);
}

.nav-icon:hover {
  border-radius: 0.375rem;
  background-color: #3e4659;
  filter: brightness(150%);
}

.sidebar-nav-item.active {
  position: relative;
  background-color: #bd9a7a;
  color: #000;
  font-size: medium;
  border-radius: 5px;
}

.sidebar-nav-item.active::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 2px;
  z-index: 2;
}

.sidebar-nav-item-group {
  text-decoration: none;
  align-items: center;
  padding: 12px;
  display: flex;
  margin-left: 10px;
  box-sizing: border-box;
  padding-left: 53px;
}

.sidebar-nav-item-group:hover {
  border-radius: 0.375rem;
  background-color: #3e4659;
  filter: brightness(150%);
}

.sidebar-nav-item-group.active {
  position: relative;
  background-color: #bd9a7a;
  color: #000;
  font-size: medium;
  border-radius: 5px;
}

.icon-nav-group {
  margin-right: 5px;
}

.sidebar-nav-item-group.active::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 2px;
  z-index: 2;
}

.sidebar-nav-item-manager.active {
  position: relative;
  background-color: #bd9a7a;
  color: #000;
  font-size: medium;
  border-radius: 5px;
}

.sidebar-nav-item-manager:hover {
  border-radius: 0.375rem;
  background-color: #3e4659;
  filter: brightness(150%);
}

.sidebar-nav-item-manager.active::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 2px;
  z-index: 2;
}

.sidebar-nav-item-manager {
  text-decoration: none;
  align-items: center;
  padding: 12px;
  display: flex;
  width: 239px;
  box-sizing: border-box;
  padding-left: 76px;
}