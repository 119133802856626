.order-section {
  margin-bottom: 20px;
}

.detail-title {
  font-weight: bold;
}

.detail-info {
  color: #333;
}

hr {
  border-top: 2px solid #000;
  margin-top: 10px;
}

.diamond-image, .jewelry-image, .certificate-image, .warranty-image {
  max-width: 250px;
  max-height: 250px;
  margin-top: 10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.card-header-custom {
  font-weight: bold;
  font-size: 1.5rem; 
  background-color: #ffffff;
  border-bottom: 2px solid #f2ba59;
}
