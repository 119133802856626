/**
 * Heroslider
 */

.tm-heroslider {
    position: relative;
    background-position: right center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex !important;
    align-items: center;

    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: rgba(#ffffff, 0.7);
        z-index: -1;
        display: none;
    }

    &-area {
        position: relative;
    }

    &-content {
        padding: 100px 0;

        h1 {
            margin-top: -10px;
            font-size: 50px;
            line-height: 64px;
            color: $color-heading;
        }

        p {
            font-size: 20px;
            line-height: 30px;
            margin-top: 30px;
            color: $color-heading;
        }

        .tm-button {
            margin-top: 25px;
        }
    }

    &-social {
        padding-left: 0;
        margin-bottom: 0;
        position: absolute;
        left: 30px;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        z-index: 5;
        text-align: center;
        padding: 15px 0;

        li {
            list-style: none;
            display: inline-block;
            margin: 0;
            position: relative;

            a {
                color: $color-heading;
                display: inline-block;
                font-size: 18px;
                text-align: center;
                background: transparent;
                padding: 5px;

                &:hover {
                    color: $color-theme;
                }
            }

            span {
                position: absolute;
                left: 100%;
                top: 50%;
                line-height: 20px;
                margin-top: -10px;
                visibility: hidden;
                opacity: 0;
                padding-left: 10px;
                font-size: 16px;
                color: $color-theme;
            }

            &:hover {
                span {
                    visibility: visible;
                    opacity: 1;
                }
            }

        }

        @media only screen and (max-width: 1350px) {
            display: none;
        }
    }

    @media #{$layout-notebook} {
        &-content {
            h1 {
                font-size: 36px;
                line-height: 46px;
            }

            p {
                font-size: 18px;
                line-height: 28px;
                margin-top: 20px;
            }

            .tm-button {
                margin-top: 15px;
            }
        }
    }

    @media #{$layout-tablet} {
        &::after {
            display: block;
        }

        &-content {
            h1 {
                font-size: 34px;
                line-height: 44px;
            }

            p {
                font-size: 16px;
                line-height: 26px;
                margin-top: 20px;
            }

            .tm-button {
                margin-top: 15px;
            }
        }
    }

    @media #{$layout-mobile-lg} {
        &::after {
            display: block;
        }

        &-content {
            padding-right: 100px;

            h1 {
                font-size: 32px;
                line-height: 42px;
            }

            p {
                font-size: 16px;
                line-height: 26px;
                margin-top: 20px;
            }

            .tm-button {
                margin-top: 15px;
            }
        }
    }

    @media #{$layout-mobile-sm} {
        &-content {
            padding-right: 0;

            h1 {
                font-size: 28px;
                line-height: 38px;
            }

            p {
                font-size: 16px;
                line-height: 26px;
                margin-top: 20px;
            }

            .tm-button {
                margin-top: 15px;
            }
        }
    }
}
.tm-heroslider-area{
    position: relative;
    overflow: hidden;
    height: 100vh;
}
.tm-heroslider-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 10px;
}
.tm-heroslider-description{
    font-size: 1.2rem;
    margin-bottom: 20px;
}

.tm-heroslider-slider {
    position: relative;
    height: 100%;
    width: 100%;
    &.slick-dotted {
        padding-bottom: 0;

        .slick-dots {
            position: absolute;
            left: 0;
            top: auto;
            bottom: 30px;
            z-index: 5;

            li {
                background: $color-body;
                width: 30px;
                height: 3px;
                border-radius: 0;

                &:hover {
                    opacity: 0.5;
                    background: $color-theme;
                }

                &.slick-active {
                    background: $color-theme;
                }
            }
        }
    }

    .slick-arrow {
        top: 50%;
        color: $color-black;
        font-size: 36px;
        transition: $transition;
        height: 65px;
        width: 65px;
        text-align: center;
        border-radius: 100px;
        background: rgba(#000000, 0.1);

        &.slick-prev {
            left: 50px;
        }

        &.slick-next {
            right: 50px;
        }

        &:hover {
            background: $color-black;
            color: #ffffff;
        }
    }

    .tm-heroslider {
        float: left;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: left;
        &-contentwrapper {
            overflow: hidden;
        }

        &-content {
            transition: all 1s ease-in-out 0.5s;
            transform: translateX(-100%);
        }

        &.slick-current {
            .tm-heroslider-content {
                transform: translateX(0);
            }
        }
    }
    .tm-heroslider-contentwrapper {
        position: relative;
        z-index: 2;
    }
    @media only screen and (max-width: 1600px) {
        .slick-arrow {
            height: 50px;
            width: 50px;
            font-size: 26px;

            &.slick-prev {
                left: 10px;
            }

            &.slick-next {
                right: 10px;
            }
        }
    }

    @media only screen and (max-width: 1300px) {
        .slick-arrow {
            top: auto;
            bottom: 20px;
            transform: translate(0, 0);
            background: rgba(#000000, 0.4);
            color: #ffffff;

            &.slick-prev {
                left: auto;
                right: calc(50% + 10px);
            }

            &.slick-next {
                left: calc(50% + 10px);
                right: auto;
            }
        }
    }

    @media #{$layout-notebook} {
        &.slick-dotted {
            .slick-dots {
                bottom: 20px;
            }
        }
    }

    @media #{$layout-tablet} {
        &.slick-dotted {
            .slick-dots {
                bottom: 20px;
            }
        }

        .slick-arrow {
            height: 40px;
            width: 40px;
            font-size: 22px;
        }
    }

    @media #{$layout-mobile-lg} {
        &.slick-dotted {
            .slick-dots {
                bottom: 20px;
            }
        }

        .slick-arrow {
            height: 40px;
            width: 40px;
            font-size: 22px;
        }
    }
}