/**
 * Animations
 */

@keyframes slideInDown {
	from {
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
		visibility: visible
	}

	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0)
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes tmFillFull {
	from {
		transform: scaleX(0);
	}

	to {
		transform: scaleX(1);
	}
}


@keyframes bannerAnim {
	0% {
		transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
		animation-timing-function: ease-in;
		opacity: 0;
	}

	40% {
		transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
		animation-timing-function: ease-in;
	}

	60% {
		transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
		opacity: 1;
	}

	80% {
		transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
	}

	100% {
		transform: perspective(400px);
	}
}

@keyframes growShrink {
	0% {
		transform: scale(0);
		opacity: 0.8;
	}

	30% {
		transform: scale(1);
		opacity: 0.5;
	}

	90% {
		transform: scale(1);
		opacity: 0.5;
	}

	100% {
		transform: scale(0);
		opacity: 0;
	}
}

@keyframes growShrink2 {
	0% {
		transform: scale(0);
		opacity: 0.8;
	}

	30% {
		transform: scale(1.2);
		opacity: 0.5;
	}

	90% {
		transform: scale(1.2);
		opacity: 0.5;
	}

	100% {
		transform: scale(0);
		opacity: 0;
	}
}