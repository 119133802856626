/**
 * Brand Logo
 */

.tm-brandlogo {
    a {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100px;
        background: #ffffff;
        transition: $transition;
        border: 1px solid $color-border;
    }
}