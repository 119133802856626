/**
 * Comment
 */
.tm-comment {
    display: flex;
    margin-bottom: 30px;

    &:last-child {
        margin-bottom: 0;
    }

    &-thumb {
        flex: 0 0 80px;
        margin-right: 25px;
        overflow: hidden;
        align-self: flex-start;

        img {
            max-width: 100%;
        }
    }

    &-content {
        @include clearfix();

        h6 {
            margin-top: -5px;
            margin-bottom: 0;
            font-size: 16px;

            a {
                color: $color-heading;

                &:hover {
                    color: $color-theme;
                }
            }
        }

        .tm-ratingbox {
            float: right;
        }

        p {
            margin-top: 8px;
            margin-bottom: 0;
        }
    }

    &-date {
        color: #757575;
    }

    &-replybutton {
        color: $color-body;
        display: inline-block;
        margin-top: 10px;
        font-weight: 600;

        i {
            font-size: 16px;
        }

        &:hover {
            color: $color-theme;
        }
    }

    &-replypost {
        padding-left: 105px;
    }

    @media #{$layout-mobile-lg} {
        &-replypost {
            padding-left: 30px;
        }
    }

    @media #{$layout-mobile-sm} {
        flex-wrap: wrap;

        &-thumb {
            flex: 0 0 60px;
            max-width: 60px;
            margin-right: 20px;
        }

        &-content {
            margin-top: 20px;

            .tm-ratingbox {
                float: none;
                margin-top: 5px;
            }
        }

        &-date {
            display: block;
        }
    }
}