.tm-cart-btns {
    display: flex;
    justify-content: space-between; 
    gap: 10px; 
    margin-top: 20px; 
}
.button-order {
    background: #f2ba59; 
    color: white; 
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    transition: color 0.3s; 
    z-index: 2; 
    font: 16px "Open Sans", sans-serif;
}

.button-order:disabled {
    background-color: #f2ba59;
    cursor: not-allowed;
}

.button-order:hover:enabled {
    animation: button-background-hover 1s forwards; /* Increased duration */
}

@keyframes button-background-hover {
    0% {
        background: #f2ba59;
    }
    100% {
        background: black; /* Fully black background */
    }
}

.button-order::after {
    content: '';
    position: absolute;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: black; 
    transition: top 0.5s; 
    z-index: 1; /* Behind the text */
}

.button-order:hover::after {
    top: 0;
}


.button-order span {
    position: relative;
    z-index: 2; 
    transition: color 0.3s; 
}

.button-order:hover span {
    color: white; 
}
